












































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import DialogoCapaDaTabelaDePreco from '@/views/application/tabela-de-precos/DialogoCapaDaTabelaDePreco.vue'
import { FiltroDaTabelaDePreco, TabelaDePreco } from '@/models'
import moment from 'moment'
import { FindTabelaDePrecoUseCase, DeleteTabelaUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { formatDate } from '@/shareds/date/date-utils'
import { DataOptions } from 'vuetify'
import axios, { CancelTokenSource } from 'axios'
import Confirmacao from '@/components/ui/Confirmacao.vue'

const FILTRO_DE_VIGENCIA = 'FILTRO_DE_VIGENCIA'

@Component({
	components: {
		DataTableDeCrud,
		DialogoCapaDaTabelaDePreco,
		Confirmacao,
		RangeDatePicker,
	},
})
export default class TelaDeTabelaDePrecos extends Vue {
	@Ref() dialogoDeConfirmacao!: Confirmacao
	@Ref() dialogoNovaTabela!: DialogoCapaDaTabelaDePreco
	tabelasDePrecos: TabelaDePreco[] = []

	headers = [
		{ text: 'Descrição', value: 'nome' },
		{ text: 'Cliente', value: 'tipoDeCliente.nome' },
		{ text: 'Tipo', value: 'tipoDeTabela' },
		{ text: 'Vigência', value: 'vigencia', align: 'center' },
		{ text: 'Grupo Econômico', value: 'grupoEconomico.descricao', align: 'center' },
	]

	carregando = false
	podeExcluir = false
	buscarSomentePromocao = false

	busca = ''
	findTabelaUseCase = new FindTabelaDePrecoUseCase()
	deleteTabelaUseCase = new DeleteTabelaUseCase()
	cancelToken: CancelTokenSource | null = null

	filtro: FiltroDaTabelaDePreco = localStorage[FILTRO_DE_VIGENCIA]
		? JSON.parse(localStorage[FILTRO_DE_VIGENCIA])
		: {
			datas: [],
		}

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	totalRegistros = -1

	async created() {
		await this.buscar()
	}
	get itensFormatados() {
		return this.tabelasDePrecos.map(tabela => ({
			...tabela,
			vigencia: `${formatDate(tabela.inicioVigencia)} à ${formatDate(tabela.fimVigencia)}`,
			tipoDeTabela: tabela.isTabelaDePromocao ? 'Promoção' : 'Comum',
		}))
	}

	get itens(){
		return this.tabelasDePrecos || []
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const params = {
				buscarSomentePromocao: this.buscarSomentePromocao ? true : null,
				nome: this.busca || undefined,
				page: this.busca ? -1 : this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				dataInicio:
					this.filtro.datas[0]
						? moment(this.filtro.datas[0]).format('YYYY-MM-DD')
						: undefined,
				dataFim:
					this.filtro.datas[1]
						? moment(this.filtro.datas[1]).format('YYYY-MM-DD')
						: undefined,
				sort: 'inicioVigencia,desc',
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const tabelas =  await this.findTabelaUseCase.find(params, axiosConfig)

			this.tabelasDePrecos = tabelas.content
			this.totalRegistros = tabelas.totalElements

			return tabelas
		} catch (error: any) {
			if (axios.isCancel(error)) return
			AlertModule.setError('Não foi possível carregar')
		} finally {
			this.carregando = false
		}
	}

	salvarCapa(capa: TabelaDePreco) {
		this.tabelasDePrecos.push({ ...capa })
	}

	mostraDialogoCapaTabelaDePreco() {
		const novaCapa = criaCapaDaTabela()
		this.dialogoNovaTabela.mostrar(novaCapa)
	}

	async removerTabela(indice: number) {
		await this.dialogoDeConfirmacao.mostrar()
		if(this.podeExcluir){
			const tabela = this.tabelasDePrecos[indice]
			if (!tabela.id) return
			try {
				await this.deleteTabelaUseCase.delete(tabela.id)
				this.tabelasDePrecos = this.tabelasDePrecos.filter(({ id }) => id !== tabela.id)
			} catch (error: any) {
				AlertModule.setError(error)
			}
		}
		this.podeExcluir = false
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar

	@Watch('filtro', { deep: true })
	onChangeFiltro(newFiltro) {
		const stringified = JSON.stringify(newFiltro)
		localStorage.setItem(FILTRO_DE_VIGENCIA, stringified)
	}

	@Watch('busca')
	onChangeBusca() {
		this.buscar()
	}
}

const criaCapaDaTabela = (): TabelaDePreco => {
	return {
		id: '',
		nome: '',
		tipoDeCliente: null,
		inicioVigencia: new Date().toISOString(),
		fimVigencia: moment().add(30, 'days').toISOString(),
		itens: [],
		isTabelaDePromocao: false,
		percentualCashback: 0,
		diasParaEfetuarCashback: 0,
		diasParaExpirarCashback: 0,
		grupoEconomico: null,
	}	
}
