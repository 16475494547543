






















import { Vue, Component, Prop } from 'vue-property-decorator'
import type { Loja } from '@/models'
import { VImg, VResponsive } from 'vuetify/lib'
import { isVestcasa } from '@/shareds/utils'

@Component({
	components: {
		VImg,
		VResponsive,
	},
})
export default class CardDeLoja extends Vue {
	@Prop({ type: Object }) loja!: Loja
	
	cor = isVestcasa ? 'primary darken-1' : '#770bea'
}

