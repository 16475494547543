














































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { Loja } from '@/models'
import { Fragment } from 'vue-fragment'
import { VListItemContent } from 'vuetify/lib'

@Component({
	components: {
		Fragment,
		VListItemContent,
	},
})
export default class ListagemDeLojasPorLista extends Vue  {
	@PropSync('value') selecionado!: Loja | null
	@Prop({ type: Array, default: () => [] }) lojas!: Loja[]
	@Prop({ type: Function }) to?: (loja: Loja) => void
	@Prop() encurtado!: boolean
}

