var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fit-content"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"-0.5%"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busca","single-line":"","hide-details":""},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}})],1)],1),_c('v-col',{staticStyle:{"margin-top":"0.5%"},attrs:{"cols":"7","sm":""}},[_c('RangeDatePicker',{attrs:{"label":"Data","loading":_vm.carregando,"disabled":_vm.carregando,"inicial":{
						label: 'Data Inicial',
						clearable: true,
					},"final":{
						label: 'Data Final',
						clearable: true,
					}},model:{value:(_vm.filtro.datas),callback:function ($$v) {_vm.$set(_vm.filtro, "datas", $$v)},expression:"filtro.datas"}})],1),_c('v-col',{staticClass:"d-flex align-center text-center",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","width":"110","type":"submit"},on:{"click":function () { return _vm.buscar(); }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Aplicar ")],1)],1),_c('v-col',{staticClass:"d-flex justify-end align-end",attrs:{"cols":"3"}},[_c('v-checkbox',{staticClass:"mr-5",attrs:{"label":"Buscar somente tabelas do tipo promoção"},on:{"click":function () { return _vm.buscar(); }},model:{value:(_vm.buscarSomentePromocao),callback:function ($$v) {_vm.buscarSomentePromocao=$$v},expression:"buscarSomentePromocao"}})],1)],1),_c('DialogoCapaDaTabelaDePreco',{ref:"dialogoNovaTabela",on:{"salvar":_vm.salvarCapa}}),_c('DataTableDeCrud',{attrs:{"search":_vm.busca,"headers":_vm.headers,"loading":_vm.carregando,"items":_vm.itensFormatados,"options":_vm.paginacao,"server-items-length":_vm.totalRegistros,"recurso":"tabela-de-precos","footer-props":{ itemsPerPageOptions: [ 5, 10, 15, 30, 50 ] }},on:{"update:options":function($event){_vm.paginacao=$event},"create":function () { return _vm.mostraDialogoCapaTabelaDePreco(); },"update":function (indice) { return _vm.$router.push({
					name: 'Edição de Tabela de Preços',
					params: { id: _vm.itensFormatados[indice].id || '' },
				}); },"delete":_vm.removerTabela}})],1),_c('Confirmacao',{ref:"dialogoDeConfirmacao",attrs:{"titulo":'Deseja excluir essa tabela de preço?'},on:{"confirmar":function($event){_vm.podeExcluir = true}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }