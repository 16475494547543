

























































































import { Vue, Component, Ref } from 'vue-property-decorator'
import SeletorDeTipoDeCliente from '@/components/tabeladepreco/SeletorDeTipoDeCliente.vue'
import DatePicker from '@/components/ui/DatePicker.vue'
import { FormCapaTabelaDePreco, TabelaDePreco } from '@/models'
import { obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {
	CreateTabelaDePrecoUseCase,
	UpdateTabelaDePrecoUseCase,
} from '@/usecases'
import BuscaDeLoja from '@/components/loja/BuscaDeLoja.vue'
import SeletorDeGruposEconomicos from '@/components/loja/SeletorDeGruposEconomicos.vue'

@Component({
	components: {
		SeletorDeTipoDeCliente,
		DatePicker,
		BuscaDeLoja,
		SeletorDeGruposEconomicos,
	},
})
export default class DialogoCapaDaTabelaDePreco extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() nomeTabela!: HTMLInputElement

	tabela: FormCapaTabelaDePreco | null = null
	obrigatorio = obrigatorio
	salvando = false
	mostra = false

	createTabelaUseCase = new CreateTabelaDePrecoUseCase()
	updateTabelaUseCase = new UpdateTabelaDePrecoUseCase()

	mostrar(tabela: TabelaDePreco) {
		this.mostra = true
		this.tabela = {...tabela}

		setTimeout(() => {
			this.form.resetValidation()
			this.nomeTabela.focus()
		})
	}

	esconder() {
		this.mostra = false
	}

	async salvar() {
		if(this.tabela && (this.tabela.percentualCashback > 0 && this.tabela.diasParaEfetuarCashback == 0) 
		) {
			return	AlertModule.setError("O campo 'Dias para efetuar Cashback' não pode estar zerado")
		} else if(this.tabela && (this.tabela.diasParaEfetuarCashback > 0 && this.tabela.percentualCashback == 0)) {
			return	AlertModule.setError("O campo 'Percentual cashback' não pode estar zerado")
		} else if(this.tabela && (this.tabela.percentualCashback > 0 && this.tabela.diasParaExpirarCashback == 0)) {
			return	AlertModule.setError("O campo 'Dias para expirar Cashback' não pode estar zerado")
		}
		if (!this.form.validate()) return
		if (!this.tabela) return
		try {
			const tabela = {
				id: this.tabela.id,
				nome: this.tabela.nome,
				tipoDeCliente: this.tabela.tipoDeCliente,
				inicioVigencia: this.tabela.inicioVigencia.substr(0, 10),
				fimVigencia: this.tabela.fimVigencia.substr(0, 10),
				isTabelaDePromocao: this.tabela.isTabelaDePromocao,
				percentualCashback:  this.tabela.percentualCashback ? this.tabela.percentualCashback : 0,
				diasParaEfetuarCashback: this.tabela.diasParaEfetuarCashback ? this.tabela.diasParaEfetuarCashback : 0,
				diasParaExpirarCashback: this.tabela.diasParaExpirarCashback ? this.tabela.diasParaExpirarCashback : 0,
				grupoEconomico: this.tabela.grupoEconomico,
			}

			const capaDaTabelaSalva = !this.tabela.id
				? await this.createTabelaUseCase.create(tabela)
				: await this.updateTabelaUseCase.update(this.tabela)

			this.$emit('salvar', {...capaDaTabelaSalva})
			this.mostra = false
		} catch (e) {
			AlertModule.setError(e)
		}
	}
}
