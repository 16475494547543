










































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { LojasAgrupadasPorEstado, ordenarLojaPorNome, ordenarLojasAgrupadasPorEstado } from '@/store/vuex/loja/LojaStore'
import CardDeLoja from '@/components/loja/CardDeLoja.vue'
import { Loja } from '@/models'

@Component({
	components: {
		CardDeLoja,
	},
})
export default class ListagemDeLojasPorCards extends Vue  {
	@PropSync('value') selecionado!: Loja | null
	@Prop({ type: Array, default: () => [] }) lojas!: Loja[]
	@Prop({ type: Function }) to?: (loja: Loja) => void
	@Prop({ type: Boolean }) encurtado!: boolean

	get lojasPorEstados(): LojasAgrupadasPorEstado[] {
		const mapLojasPorEstado: { [uf: string]: Loja[] } = this.lojas.reduce(
			(mapLojasPorEstado, loja) => ({
				...mapLojasPorEstado,
				[loja.endereco.uf]: [
					...(mapLojasPorEstado[loja.endereco.uf] || []),
					loja,
				],
			}),
			{},
		)
		return Object.entries(mapLojasPorEstado)
			.map(([estado, lojas]) => ({
				estado,
				lojas: lojas.sort(ordenarLojaPorNome),
			}))
			.sort(ordenarLojasAgrupadasPorEstado)
	}
}

